var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ mobile: _vm.isMobile }},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"content-wrap huobao-wrap f-bet"},_vm._l((45),function(item){return _c('img',{key:item,attrs:{"src":`/logo/logo_${item}.png`,"alt":"卡车侠合作伙伴"},on:{"mouseover":_vm.imgAddClass}})}),0),_vm._m(8),_c('div',{staticClass:"content-wrap f-bet flex-col"},[_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/eOpt-Rbd7tLfOfDyQb4thw","target":"_blank"}},[_c('div',{staticClass:"news1"},[_c('img',{attrs:{"src":require("@/assets/imgs/news.webp"),"alt":"卡车侠资讯"}}),_c('div',{staticClass:"news-bg"},[_c('div',{staticClass:"news-title"},[_vm._v(" 商用车的后市场如何？ ")]),_vm._v(" 商用车作为生产资料其市场从本质上区别于乘用车市场。"),(!_vm.isMobile)?[_c('br')]:_vm._e(),_vm._v(" 商用车后市场是涵盖整车、发动机、零部件、经销商"),(!_vm.isMobile)?[_vm._v("、维修企业、保险")]:_vm._e(),_vm._v(" ... ")],2)])]),_vm._m(9)]),_vm._m(10),_vm._m(11),_c('div',{staticClass:"content-wrap footer-wrap f-bet flex-col"},[_c('div',{staticClass:"f-logo"},[(!_vm.isMobile)?_c('img',{attrs:{"src":require("@/assets/imgs/b-logo.png"),"alt":"卡车侠"}}):_vm._e(),_c('div',{staticClass:"title"},[_vm._v("杭州卡车侠科技有限公司(400-999-8590)")]),_vm._m(12)]),(!_vm.isMobile)?_c('div',{staticClass:"home-msg"},[_c('div',{staticClass:"home-title"},[_vm._v("首页")]),_c('div',[_vm._v("核心优势")]),_c('div',[_vm._v("解决方案")]),_c('div',[_vm._v("合作伙伴")])]):_vm._e(),_vm._m(13)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-wrap"},[_c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"logo-wrap flex f-ac"},[_c('img',{attrs:{"src":require("@/assets/imgs/logo.png"),"alt":"卡车侠 truck man"}}),_c('div',{staticClass:"kcx-logo"},[_c('div',[_vm._v("卡车侠")]),_vm._v("TRUCK MAN ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-wrap"},[_c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"banner-title"},[_vm._v(" 专为商用车设计 ")]),_c('p',[_vm._v("卡车侠TRUCK MAN® 平台致力于帮助车队以及卡车司机与"),_c('br'),_vm._v(" 全国各地的供应商、商家门店建立直接关系，并提供优质的加油、"),_c('br'),_vm._v(" 保养、卡车维修和故障事故等车辆服务，为司机节省大量时间和成本。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap tc"},[_c('div',{staticClass:"bz-title"},[_vm._v("更好地管理故障")]),_c('div',{staticClass:"bz-desc"},[_vm._v("您知道吗?数千个卡车车队以及卡车司机使用卡车侠TRUCK MAN® 来更好地管理卡车故障、维修和设备维护等车辆服务。")]),_c('div',{staticClass:"f-bet flex-col"},[_c('div',{staticClass:"bz1"},[_c('div',[_vm._v("免费账号")]),_c('div',{staticClass:"bz-con"},[_vm._v("对于车队用户来说，卡车侠TRUCK MAN®是免费的，我们的免费帐户可以让您完全访问直观的功能，帮助您更好地跟踪每个供应商的体验，保存首选位置，查看供应商服务、评级等等。")])]),_c('div',{staticClass:"bz2"},[_c('div',{staticClass:"bz-tit"},[_vm._v("每日更新")]),_c('div',{staticClass:"bz-con"},[_vm._v("我们的团队每天都会通过电话验证和更新卡车侠TRUCK MAN®供应商位置。我们每年至少两次验证数据库中的每个供应商列表，以便为您和您的团队提供最新的信息。")])]),_c('div',{staticClass:"bz3"},[_c('div',{staticClass:"bz-tit"},[_vm._v("任何服务")]),_c('div',{staticClass:"bz-con"},[_vm._v("值得信赖的来源，自2022年以来，卡车侠TRUCK MAN®一直是业界值得信赖且领先的服务资源。业内一些最大的车队、故障呼叫中心和成千上万的卡车司机每天都会使用我们的搜索功能。")])]),_c('div',{staticClass:"bz4"},[_c('div',{staticClass:"bz-tit"},[_vm._v("私人地点")]),_c('div',{staticClass:"bz-con"},[_vm._v("卡车侠TRUCK MAN®管理着超过 30,000 个地点，但我们知道我们无法列出您的车队使用的每个地点，因此 我们为您创建了一项功能，可在几秒钟内将任何缺失的地点添加到搜索中。没有中间人!")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-wrap fg"},[_c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"banner-user"},[_vm._v(" 卡车侠司机用户 ")]),_c('div',{staticClass:"banner-title"},[_vm._v(" 卡车侠 APP ")]),_c('p',[_vm._v("卡车侠APP是业界领先的卡车服务电商平台，"),_c('br'),_vm._v(" 为卡车司机提供全国超过30,000个服务网点。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-wrap fangan tc"},[_vm._v(" 我们解决的"),_c('span',[_vm._v("问题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"laike-wrap"},[_c('div',{staticClass:"content-wrap flex"},[_c('div',{staticClass:"laike-content"},[_c('div',[_c('span',{staticClass:"lk-title"},[_vm._v("平台曝光多：")]),_vm._v(" 商用车商家完成入驻流程后，在卡车侠平台会展示线上门店，用户打开卡车侠即可看到线上门店团购活动以及门店的品牌、位置、商品、评价等信息。 ")]),_c('div',[_c('span',{staticClass:"lk-title"},[_vm._v("客流覆盖广：")]),_vm._v(" 商用车商家可以通过团购，代金券，买单，晚定等各种合作方式，吸引新客到门店消费，扩展更多客流。 ")]),_c('div',[_c('span',{staticClass:"lk-title"},[_vm._v("服务体系好：")]),_vm._v(" 专业人员提供合作湾程全指导，制定针对性运营计划，薯助商用车商家经营更好，生意更好，同时提供专属的市核通道，最快当日申请，当日即可成功入驻。 ")]),_c('div',[_vm._v(" 致力于为卡车司机提供优质的消费体验，帮助商家创造更多生意，带东美好生活。商家在线上开店后可合作团购，代金券，买单，预定等丰富的活动，帮助加油站，轮胎门店以及保养维修门店等商户沉淀口碑，获取用户，增加复购等，进而轻松管理商户。 ")])]),_c('img',{staticClass:"app",attrs:{"src":require("@/assets/imgs/lk.png"),"alt":"卡车侠来客app"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"laike-wrap kcx"},[_c('div',{staticClass:"content-wrap flex"},[_c('img',{staticClass:"app",attrs:{"src":require("@/assets/imgs/kcx.png"),"alt":"卡车侠来客app"}}),_c('div',{staticClass:"laike-content"},[_c('div',[_vm._v(" 卡车侠作为一个商用车后市场 O2O 电商平台，对用户有以下好处： ")]),_c('div',[_c('span',{staticClass:"lk-title"},[_vm._v("提供便捷的服务：")]),_vm._v(" 用户可以通过平台轻松找到附近的商用车服务提供商，如维修店、加油站、轮胎店等，并进行在线预约和支付。 ")]),_c('div',[_c('span',{staticClass:"lk-title"},[_vm._v("比较价格和服务：")]),_vm._v(" 平台上有多个服务提供商，用户可以比较不同提供商的价格和服务质量，选择最适合自己的服务。 ")]),_c('div',[_c('span',{staticClass:"lk-title"},[_vm._v("保障服务质量：")]),_vm._v(" 平台对服务提供商进行审核和评价，用户可以查看其他用户的评价和反馈，选择信誉良好的服务提供商。 ")]),_c('div',[_c('span',{staticClass:"lk-title"},[_vm._v("节省时间和成本：")]),_vm._v(" 用户可以通过平台预约服务，避免了等待和排队的时间，同时也可以节省维修和保养的成本。 ")]),_c('div',[_c('span',{staticClass:"lk-title"},[_vm._v("提供专业的建议：")]),_vm._v(" 平台上有专业的技师和顾问，用户可以咨询他们关于商用车的维修和保养问题，获得专业的建议和指导。 ")]),_c('div',[_vm._v(" 总之，卡车侠作为一个商用车后市场 O2O 电商平台，为用户提供了便捷、高效、优质的服务，帮助用户节省时间和成本，提高商用车的运营效率和安全性 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"huoban tc"},[_vm._v("卡车侠合作伙伴")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"huoban tc"},[_vm._v("新闻资讯")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/-kVsubJjuSv4UAUgxWNBQA","target":"_blank"}},[_c('div',{staticClass:"news2 news-bg new-mb"},[_c('div',{staticClass:"news-title"},[_vm._v(" 商用车市场形势 ")]),_vm._v(" 过去三十年，商用车市场具有明显的阶段性特征"),_c('br'),_vm._v(" 背后是经济发展阶段的变化：“GDP增长率+经济结构的变化”；政策法规 ... ")])]),_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/BxzpO2ObQtyMOmNlWHEsoQ","target":"_blank"}},[_c('div',{staticClass:"news2 news-bg"},[_c('div',{staticClass:"news-title"},[_vm._v(" 大车队背景下的后市场变革 ")]),_vm._v(" 快递企业“成长的烦恼”"),_c('br'),_vm._v(" 快递企业发展的主要矛盾：目前汽车后市场服务与快递车队不匹配，具体... ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap f-bet flex-col"},[_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/Cq4oD7KjaJz9rDiXzPqK8w","target":"_blank"}},[_c('div',{staticClass:"news2 news-bg new3"},[_c('div',{staticClass:"news-title"},[_vm._v(" 用产品思维来看待商用车后市场 ")]),_vm._v(" 纵观整个商用车市场，从整个车后生态产品来看除了ETC跟柴油，"),_c('br'),_vm._v(" 其他车后产品的信息仍然处于不对称阶段，在业内常用的定义口径中 ... ")])]),_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/bY0hFJWNxlMcPkxbBxy1vw","target":"_blank"}},[_c('div',{staticClass:"news2 news-bg new4"},[_c('div',{staticClass:"news-title"},[_vm._v(" 中国商用车后市场趋势展望 ")]),_vm._v(" 受运费竞争、运输效率提升等驱动，对集约的后市场服务、物流使能的市场"),_c('br'),_vm._v(" 增长潜力逐步释放，服务赋能市场蕴含巨大机遇 价值链机会潜力 ... ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-wrap bottom"},[_c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"banner-title"},[_vm._v(" 卡车侠TRUCK MAN® ")]),_c('p',[_vm._v("不提供中介服务。我们免费为您提供全国商家服务网络，"),_c('br'),_vm._v(" 以便您直接与商家协商价格和时间，从而选择速度最快、"),_c('br'),_vm._v("最好的商家为您服务。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add f-ac"},[_c('img',{attrs:{"src":require("@/assets/imgs/lo.png"),"alt":"卡车侠"}}),_vm._v(" 浙江省杭州市拱墅区候圣街99号财智顺丰创新中心4幢301室 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-ac tc"},[_c('div',{staticClass:"app wx"},[_c('img',{attrs:{"src":require("@/assets/imgs/wx.png"),"alt":"卡车侠微信公众号"}}),_vm._v(" 卡车侠公众号 ")]),_c('div',{staticClass:"app"},[_c('img',{attrs:{"src":require("@/assets/imgs/kcxapp.jpg"),"alt":"卡车侠APP"}}),_vm._v(" 卡车侠司机端 ")]),_c('div',{staticClass:"app"},[_c('img',{attrs:{"src":require("@/assets/imgs/lkapp.jpg"),"alt":"卡车侠来客"}}),_vm._v(" 卡车侠商家端 ")])])
}]

export { render, staticRenderFns }